import React, { useMemo, useState, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductTitle.module.scss';
import {
  Link,
  ProductPrice,
  ProductOrderButton,
  ResponsiveLazyImage,
  StarsRating,
} from 'sana/elements';
import { productPage } from 'sana/routes';
import { LazyContent } from '../../utils/lazyLoader';
import classNames from 'classnames';
import { SimpleText, UseSanaTexts } from 'sana/texts';

const ProductTile = React.forwardRef(({
    contentId, product, showRatings,
    noImage, isLastItem, isFirstItem,
    setFocusToRightButton, setFocusToLeftButton,
  }, ref) => {
  const refTitle = useRef();
  const refOrderButton = useRef();

  useImperativeHandle(ref, () => ({
    get title() {
      return refTitle.current;
    },
    get orderButton() {
      return refOrderButton.current;
    },
  }));

  const [isReachToListEnd, setIsReachToListEnd] = useState(false);
  const to = useMemo(() => productPage(product.id), [product]);
  const isConfigurable =
    product.productConfiguratorInfo &&
    product.productConfiguratorInfo.isProductConfigurable;
  const productImage =
    product.image && product.image.medium ? product.image.medium : noImage;

  const onKeyDownProductTitle = event => {
    if ([' ', 'Enter'].includes(event.key)) {
      window.location = product.url;
    }
    else if (isFirstItem && event?.shiftKey && event?.key === 'Tab') {
      event.preventDefault();
      setTimeout(() => {
        setFocusToLeftButton();
      }, 200);
    }
  };
  
  const onBlurEndContainer = () => {
    setIsReachToListEnd(!isReachToListEnd);
  };

  const onKeyDownButtonContainer = e=>{
    if(!isLastItem)
      return;
    if(e.shiftKey && e.key === 'Tab'){
      e.preventDefault();
      setTimeout(() => {
       refTitle.current.focus();
      }, 100);
    }
    else if(e.key === 'Tab')
    {
      e.preventDefault();
      setTimeout(() => {
        setFocusToRightButton();
      }, 100);
    }
  };

  return (
    <div className={styles.productTile}>
      <div className={styles.productDetails} tabIndex={-1}>
        <div className={styles.productInner}>
          <div className={styles.imgWrp}>
            <div className={styles.imgContainer}>
              <LazyContent wait={product.id && productImage} className={styles.skeltoncon}>
                <Link url={product.url} to={to} className={styles.imgInnerContainer} tabIndex={-1} aria-hidden>
                  <ResponsiveLazyImage
                    src={productImage}
                    alt={product.title}
                    className={classNames(
                      styles.image,
                      productImage === noImage && styles.noImage,
                    )}
                    draggable="false"
                    aria-hidden
                    aria-disabled
                  />
                </Link>
              </LazyContent>
            </div>
          </div>
        </div>

        <div className={styles.productTitle}>
          <LazyContent wait={product.title} className={styles.titlePlaceholder} tabIndex={0}>
            <Link id={`${contentId}-${product.id}`} ref={refTitle} tabIndex={0} onKeyDown={onKeyDownProductTitle} url={product.url} to={to}>
              {product.title}
            </Link>
          </LazyContent>
        </div>

        <div className={styles.itemNoWrapper}>
          <LazyContent wait={product.id} className={styles.itemNoPlaceholder}>
            <div className={styles.itemNoContent}>
              <span aria-hidden>{product.id}</span>
              <UseSanaTexts textKeys={['ItemCode']}>
                {([ItemCode]) =>
                  (<span className="sr-only">{`${ItemCode} : ${product.id}`}</span>)}
              </UseSanaTexts>
            </div>
          </LazyContent>
        </div>

        <div className={styles.productPrice}>
          <LazyContent wait={product.price} className={styles.pricesPlaceholder}>
            <ProductPrice
              salesPrice={product.price}
              basePrice={product.listPrice}
            />
          </LazyContent>
        </div>

        {showRatings && product.reviews && (
          <LazyContent
            wait={product.reviews.total}
            className={styles.reviewPlaceholder}
          >
            <Link
              url={`${product.url}#reviews`}
              to={to}
              className={styles.reviewWrapper}
            >
              <StarsRating value={product.reviews.avg} />
              <div className={styles.reviewTotal}>
                ({product.reviews.total} <SimpleText textKey={'Reviews'} />)
              </div>
            </Link>
          </LazyContent>
        )}
        <div
          id={`${contentId}-end-container-${product.id}`}
          ref={refOrderButton}
          className={styles.productOrderButtonWrapper}
          tabIndex={isReachToListEnd && isLastItem ? 0 : -1}
          onBlur={onBlurEndContainer}
          onKeyDown={onKeyDownButtonContainer}
        >
          <LazyContent wait={product.id} className={styles.buttonPlaceholder}>
            <ProductOrderButton
              productId={product.id}
              url={product.url}
              to={to}
              uom={product.uom}
              isOrderable={product.isOrderable}
              isConfigurable={isConfigurable}
              hasVariants={product.hasVariants}
            />
          </LazyContent>
        </div>
      </div>
    </div>
  );
});

ProductTile.propTypes = {
  contentId: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
    price: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    uom: PropTypes.object,
    isOrderable: PropTypes.bool,
    hasVariants: PropTypes.bool,
    productConfiguratorInfo: PropTypes.shape({
      isProductConfigurable: PropTypes.bool,
    }),
    reviews:PropTypes.object,
  }),
  noImage: PropTypes.string,
  setFocusToRightButton: PropTypes.func,
  setFocusToLeftButton: PropTypes.func,
  showRatings: PropTypes.bool,
  isLastItem: PropTypes.bool,
  isFirstItem: PropTypes.bool,
};
export default ProductTile;
