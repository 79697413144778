import { ofType } from 'redux-observable';
import { map, switchMap, pluck, groupBy, mergeMap } from 'rxjs/operators';
import { PRODUCT_SET_REQUESTED, productSetReceived } from './actions';
import { productsQuery,productsWithReviewsQuery } from './queries';

export default (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(PRODUCT_SET_REQUESTED),
    groupBy(action => action.payload.contentBlockId),
    mergeMap(group =>
      group.pipe(
        switchMap(
          ({
            payload: { contentBlockId, productSetId, sorting, size, index, reviews },
          }) => {
           
            const variables = {
              id: productSetId,
              options: {
                page: {
                  index,
                  size,
                },
                sorting,
                ignoreGrouping:true,
              },
            };

            return api.graphApi(reviews ? productsWithReviewsQuery : productsQuery, variables).pipe(
              pluck('catalog', 'productSet'),
              map(productSet =>
                productSetReceived(
                  contentBlockId,
                  getProductSetProducts(productSet),
                ),
              ),
            );
          },
        ),
      ),
    ),
  );

  function getProductSetProducts(productSet) {
      return productSet;
  }
};