const productModel = {
  id: undefined,
  url: undefined,
  image: { medium: undefined },
  name: undefined,
  title: undefined,
  price: undefined,
};
export const getDefaultProductList = itemPerRow => {
  const list = [];
  for (let i = 0; i < itemPerRow; i++) {
    list.push(productModel);
  }
  return list;
};

export const getPageInfo = (mediaContext, model) => {
  const { xs, sm, md, lg, xl } = mediaContext;
  const {
      productsOnExtraLargeScreen,
      productsOnLargeScreen,
      productsOnMediumScreen,
      productsOnSmallScreen,
      productsOnExtraSmallScreen,
  } = model;

  let itemPerRow = productsOnExtraLargeScreen;
  let itemColsize = null;
  if (xl) {
    itemPerRow = productsOnExtraLargeScreen;
    itemColsize = 100 / productsOnExtraLargeScreen;
  } else if (lg) {
    itemPerRow = productsOnLargeScreen;
    itemColsize = 100 / productsOnLargeScreen;
  } else if (md) {
    itemPerRow = productsOnMediumScreen;
    itemColsize = 100 / productsOnMediumScreen;
  } else if (sm) {
    itemPerRow = productsOnSmallScreen;
    itemColsize = 100 / productsOnSmallScreen;
  } else if (xs) {
    itemPerRow = productsOnExtraSmallScreen;
    itemColsize = 100 / productsOnExtraSmallScreen;
  }
  const pageSize = itemPerRow;
  return { pageSize, itemPerRow, itemColsize };
};

export const getStyles = (id, model) => `
  <style>
      .ssr-markup #product-set-carousel-${id} .slick-track {
        width:100% !important; 
      }

      @media (min-width: 1920px) {
        .ssr-markup #product-set-carousel-${id} .slick-slide { 
          display:none;
          width: ${ 100 / model.productsOnExtraLargeScreen }% !important
        }

        .ssr-markup #product-set-carousel-${id} .slick-slide:nth-child(-n+${model.productsOnExtraLargeScreen}) {
          display:block;   
        }
      }

      @media (min-width: 1280px) and (max-width: 1919px) {
        .ssr-markup #product-set-carousel-${id} .slick-slide { 
          display:none;
          width: ${100 / model.productsOnLargeScreen }% !important
        }

        .ssr-markup #product-set-carousel-${id} .slick-slide:nth-child(-n+${model.productsOnLargeScreen}) {
          display:block;   
        }
      }

      @media (min-width: 960px) and (max-width: 1279px) {
        .ssr-markup #product-set-carousel-${id} .slick-slide { 
          display:none;
          width: ${ 100 / model.productsOnMediumScreen }% !important
        }

        .ssr-markup #product-set-carousel-${id} .slick-slide:nth-child(-n+${model.productsOnMediumScreen}) {
          display:block;   
        }
      }

      @media (min-width: 600px) and (max-width: 959px) {
        .ssr-markup #product-set-carousel-${id} .slick-slide { 
          display:none;
          width: ${ 100 / model.productsOnSmallScreen }% !important;
        }

        .ssr-markup #product-set-carousel-${id} .slick-slide:nth-child(-n+${model.productsOnSmallScreen}) {
          display:block;   
        }
      }

      @media (max-width: 599px) {
        .ssr-markup #product-set-carousel-${id} .slick-slide { 
          display:none;
          width: ${ 100 / model.productsOnExtraSmallScreen }% !important;
        }

        .ssr-markup #product-set-carousel-${id} .slick-slide:nth-child(-n+${model.productsOnExtraSmallScreen}) {
          display:block;   
        }
      }
  </style>
  `;