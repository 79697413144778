import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ProductSetCarousel.module.scss';
import { urlEscape } from '../../utils/helpers';
import classNames from 'classnames';

const LeftArrow = React.forwardRef(({ id, imagePath, onClick, disabled, onKeyDown }, ref) => {
  return (
    <button
      id={id}
      ref={ref}
      className={classNames('product-set-carousel-previous',styles.prev)} 
      onClick={onClick}
      tabIndex={0}
      onKeyDown={onKeyDown}
      aria-label="Left arrow"
      aria-disabled={disabled}
    > 
       <img
         src={urlEscape(imagePath)}
         className={classNames(styles.navigationIcon, disabled ? styles.btnDisabled  : '')}
         alt=""
         aria-disabled={disabled}
       />
    </button>
  );
});

LeftArrow.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  imagePath: PropTypes.string,
  disabled:PropTypes.bool,
};

export default LeftArrow;
