export const urlEscape = url => {
  url = unescape(url);
  const onlineImagePattern = /^((http|https):\/\/)/;
  return !onlineImagePattern.test(url) ? escape(url) : url;
};

export const ignoreDuplicate = items =>
  items.filter(
    ({ id }, index) => !items.map(x => x.id).includes(id, index + 1),
  );
